<template>
  <div>
    <v-row> 
      <v-col cols="12"> 
          <Lista />
      </v-col>
    </v-row>
  </div>
</template>
 
<script>
import Lista from './componentes/Lista.vue';
import Upload from './componentes/Upload.vue';
export default {
  components: {
    Lista,
    Upload
  },
}
</script>
 